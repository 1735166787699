@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Montserrat:wght@400;700;900&family=Nunito:wght@700&display=swap');

/* need static version for text stroke to work properly */
@font-face {
  font-family: 'MontserratStatic';
  src: local(''),
        url('./Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'MDLZBiteType';
  src: local(''),
        url('./MDLZBITETYPE-Regular-a8494c2adc767c4e1aa9a688436b0a51.woff2') format('woff2');
}
